<template>
    <div class="block">
        <small v-if="data.BottomText" :class="data.BottomTextClass">{{ data.BottomText }}</small>
        <b-field class="mb-0" :label="data.Label">
            <b-autocomplete
                v-model="localfield"
                ref="autocomplete"
                :data="data.Source"
                field="Name"
                :loading="loading"
                :placeholder="data.Placeholder"
                @select="option => selected = option"
                @typing="onTyping"
            >
                <template slot="empty">No results for {{ localfield }}</template>
            </b-autocomplete>
            <input type="hidden" :name="data.FieldToSave" :value="selected ? selected.Oid : ''">
        </b-field>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object
        }
    },
    data () {
        return {
            localfield: '',
            selected: {},
            loading: false
        }
    },
    methods: {
        async onTyping (e) {
            console.log('typing')
            var data = {
                term: e
            }
            var url = this.stringReader(this.data.onInput.get, data)
            if (e.length < 3) return false
            try {
                this.loading = true
                this.data.Source = await this.$baseApi.get(url)
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
        setDefault () {
            var defaultValue = this.data.Default
            if (!defaultValue) return false
            this.selected = defaultValue
            this.localfield = defaultValue.Name
        }
    },
    mounted () {
        this.setDefault()
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .autocomplete {
        width: 100%;
    }
    // ::v-deep .dropdown-content {
    //     width: 200%;
    // }
    // ::v-deep .dropdown-item {
    //     font-size: 10px;
    // }
</style>
